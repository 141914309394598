.table-box{
    padding-top: 100px;
    margin: 0 20px;
}
.titles{
    background-color: #9b2743;
    color:#fff
}
.export-button{
    margin-bottom: 50px;
    background: #fff;
    border: 1.5px solid rgba(0, 0, 0, 0.3);
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.3);
    float: right;

}
.export-button:hover{
    background: #9B2743;
    color: #fff;
    font-weight: 800;
    border: 1.5px solid #fff;
}
.select-cotizadores{
    display: flex;
    align-items: center;
    box-sizing: border-box;

    margin-bottom: 20px;
  
}
.categorie{
    background-color: #fff;
    color: #b7b6b6;
    margin: 20px 4px 10px 0;
    font-weight: 700;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    padding: 6px 10px;
    line-height: 15px;
    cursor: pointer;
    width: 200px;
    text-align: center;
    border: 2px solid #b7b6b6;
    border-radius: 8px;
}
.categorie:hover{
    background-color: #9B2743;
    color: #fff;
}
.categorie-selected{
    background-color: #fff;
    color: #9B2743;
    margin: 20px 4px 10px 0;
    font-weight: 700;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    padding: 6px 10px;
    line-height: 15px;
    cursor: pointer;
    width: 200px;
    text-align: center;
    border: 2px solid #9B2743;
    border-radius: 8px;
}