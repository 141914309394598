.home-background{
    background-image: url(../../../public/img/Puertahexagonal.jpg);
    background-size: cover ;
    min-height: 100vh;
    text-align: center;
    margin: 0;
}
.login-title{
    padding-top: 26%;
    font-size: 40px;
    color: #fff;
    margin-top: 0;
    margin-bottom: 100px;
    font-family: 'Sora', sans-serif;
    font-weight: 600;
}
.white-input{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #fff;
    width: 300px;
    height: 2.5em;
    padding-left: 5px;
    background-color: rgba(255, 255, 355, .4);
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: .8em;
    color: #fff;
}
.input-box{
    margin-top: 16px;
}
.in-button{
    margin-top: 12px;
    background: rgba(0, 0, 0, 0.3);
    border: 1.5px solid #fff;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #fff;

}
.in-button:hover{
    background: #9B2743;
    font-weight: 800;
}
.white-input::placeholder { 
  color:#fff;
  }
.white-input:-ms-input-placeholder { 
  color: #fff;
  }
.white-input::-ms-input-placeholder {
    color: #fff;
  }
   