.loader-container {
    display: none;
    width: 300px;
    height: 4px;
    margin: 16px auto 0
    
  }
  
  .loader-bar {
    height: 100%;
    background-color: #dbd334; /* Adjust color as needed */
    animation: loader 2s linear infinite; /* Adjust animation duration as needed */
  }
  
  @keyframes loader {
    0% { width: 0; }
    100% { width: 100%; }
  }
  
  .visible {
    display: block;
  }