@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

body{
  margin: 0;
  padding: 0;
  height: 100%;
/*   overflow-x: hidden; */
  max-width: 100%;
  font-family: 'Sora', sans-serif;
 }
a{
  text-decoration: none;
  font-family: 'Sora', sans-serif;
  color: #fff;
}
a:hover{
  color: #fff;
}
h1{
  margin: 0;
  padding: 0;
  font-family: 'Sora';
  font-weight: 400;
  font-size: 4.7em;
  line-height: 1em;
  letter-spacing: -0.04em;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: .35em;
}
h2{
  margin: 0;
  padding: 0;
}
h3{
  margin: 0;
  padding: 0;
}
h4{
  margin: 0;
  padding: 0;
  font-family: 'Sora', sans-serif;
  font-weight: 600;
  font-size: 14.5px;
  color: #B7B6B6;
}
h5{
  font-family: 'Sora', sans-serif;
  margin: 0;
  padding: 0;
  
}
h6{
  font-family: 'Sora', sans-serif;
  margin: 0;
  padding: 0;

}
p{ margin: 0;
  padding: 0;
  font-family: 'Sora', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}
div{
  margin: 0;
  padding: 0;
}