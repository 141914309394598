.form-box-prensa{
    width: 100vw;
    padding: 130px 40px 0 40px;
    display: flex;
    
    box-sizing: border-box;

}
.see-button{
    margin-top: 12px;
    background: rgba(0, 0, 0, 0.3);
    border: 1.5px solid #fff;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 180px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #fff;
    margin-right: 20px;

}
.see-button:hover{
    background: #9B2743;
    font-weight: 800;
}

.form{
    width: 75%;
}