.headerBox{
    z-index: 100;
    position: absolute;
    width: 100vw;
}
.header-in{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 30px;
    padding: 10px 40px 0 40px;
    align-items: center;
    justify-content: left;
}
.logoAfz{
    width: 100%;
    margin: 0;
    grid-column: 1;
}
.header-menu{
    background-color: #9B2743;
    border-radius: 16px;
    width: 100%;
    margin-top: 18px;
    grid-column: 2/8;
    display: flex;
    justify-content: space-between;
    height: 32px;
    column-gap: 2em;
    text-decoration: none;
    
}
.header-item{
    margin: 2px 4px 4px 4px;
    padding: 1px 10px 0;
    text-decoration: none;
}

.header-item:nth-child(5){
    flex-grow: 2;  
}

.header-category{
    font-family: 'Sora', sans-serif;
    font-weight: 500;
    font-size: 14.5px;
    display: inline-block;
    position: relative;
    margin-bottom: 3px;
    margin-top: 3px;
    color: #B7B6B6;
    text-decoration: none;
}

.header-category::after{
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    color: #B7B6B6;
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: -5px;
    left:0;
    background-color:#fff; 
    transform-origin: right;
    transition: transform 0.25s ease-out;
    text-decoration: none;
}
.header-category:hover::after{
    transform: scaleX(1);
    transform-origin: left;
    text-decoration: none;
}
.header-category:hover{
    font-family: 'Sora', sans-serif;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
}

.header-category-selected{
    display: inline-block;
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: 14.5px;
    color: #fff;
    border-bottom: 3.5px solid #fff;
    margin-bottom: 3px;
    margin-top: 3px;
    padding-bottom: 1px;  
    text-decoration: none;
}
.header-category-selected:hover{
    text-decoration: none;
    color: #fff;
}

.header-category-special{
    font-weight: 700;
    font-size: 14.5px;
    color: #fff;
    
} 
.header-category-special:hover{
    font-weight: 800;
    color: #fff;
    text-decoration: none;
    text-shadow: 1px 1px 2px #767474;
    
} 
.display-none{
    display: none;
}

@media only screen and (min-width: 1440px){
    .hero{
        width: 100vw;
    }
    .header-category {
        font-size: 15px;
    }
    .logoAfz{
        width: 90%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .header-category{
        font-size: 12px;
       }
    .header-category-selected{
        font-size: 12px;
    }
    .header-menu{
        column-gap: 1em;
        grid-column: 1/8;
        margin-top: 5px;
        height: 28px;
        }
        .header-in{
            column-gap: 15px;
        }
        .logoAfz{
            width: 120%;
        }
    }
   